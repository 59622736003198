<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
            </div>
        </div>
        <el-table
            :data="tableData"
            border
            class="report-table-3"
            row-key="uuid"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            lazy
            height="calc(100vh - 230px)"
            :load="getMonthCategoryData"
        >
            <el-table-column align="left" prop="title" label="汇总/年份">
                <template slot-scope="scope">
                    <span :style="`margin-left: ${scope.row.title === '总计' ? 28 : 0}px`">
                        {{ scope.row.title }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="right" label="投放成本">
                <template slot-scope="scope">
                    <NumFormat unit="万" :key="scope.row.uuid" :num="scope.row.cost / 100" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" label="流水">
                <template slot-scope="scope">
                    <NumFormat unit="万" :key="scope.row.uuid" :num="scope.row.fee / 100" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="feeRoi" label="ROI" />

            <el-table-column align="right" prop="costScale" label="费比">
                <template slot-scope="scope">
                    <span class="stress">{{ scope.row.costScale }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column align="right" label="退费">
                <template slot-scope="scope">
                    <NumFormat unit="万" :key="scope.row.uuid" :num="scope.row.refundFee / 100" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="refundFeeScale" label="退费率" /> -->
        </el-table>
        <Watermark />
    </div>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { getProviderCategoryList } from '@/js/api/advertApi.js'
import { getPercentage, numFormat } from '@/js/numTools.js'
import { getOverviewOperating } from '@/js/api/reportApi.js'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
const numDate = ['cost', 'currentFee', 'fee', 'periodFee', 'refundFee']

export default {
    components: { ProjectSelect },
    data() {
        return {
            projectNo: '',
            tableData: [],
        }
    },
    created() {
        this.getProviderCategoryListApi()
    },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.getOverviewOperatingApi()
        },
        getOverviewOperatingApi() {
            getOverviewOperating({ projectNo: this.projectNo }).then((res) => {
                let total = { ...this.getTableItem('总计') }
                const yearMap = {}

                for (let i = 0; i < res.data.length; i++) {
                    const item = res.data[i]
                    item.uuid = uuidv4()
                    item.hasChildren = true
                    item.title = item.month
                    res.data[i] = { ...item, ...this.updateItemData(item) }
                    // 总计
                    numDate.forEach((num) => {
                        total[num] += item[num]
                    })
                    const year = item.month.substr(0, 4)
                    if (!yearMap[year]) {
                        yearMap[year] = { ...this.getTableItem(year), children: [] }
                    }
                    yearMap[year].children.push(res.data[i])
                    numDate.forEach((num) => {
                        yearMap[year][num] += item[num]
                    })
                }
                total = { ...total, ...this.updateItemData(total) }
                res.data.push(total)
                const tableData = Object.values(yearMap)
                for (let j = 0; j < tableData.length; j++) {
                    const item = tableData[j]
                    item.children.sort((a, b) => dayjs(b.month).valueOf() - dayjs(a.month).valueOf())
                    tableData[j] = { ...item, ...this.updateItemData(item) }
                }
                tableData.push(total)
                this.tableData = tableData.reverse()
            })
        },
        getMonthCategoryData(row, treeNode, resolve) {
            getOverviewOperating({
                projectNo: this.projectNo,
                month: row.month,
                level: 'category',
            }).then((res) => {
                for (let index = 0; index < res.data.length; index++) {
                    const item = res.data[index]
                    const _category = this.providerCategoryMap[item.categoryNo]
                    item.uuid = uuidv4()
                    item.hasChildren = false
                    item.title = _category ? _category.name : '-'
                    item.categorySeq = _category ? _category.seq : '-'
                    res.data[index] = { ...item, ...this.updateItemData(item) }
                }
                res.data.sort((a, b) => a.categorySeq - b.categorySeq)
                resolve(res.data)
            })
        },
        getTableItem(title) {
            return {
                uuid: uuidv4(),
                cost: 0,
                currentFee: 0,
                fee: 0,
                title: title,
                costScale: '-',
                refundFeeScale: '-',
                periodFee: 0,
                refundFee: 0,
            }
        },
        getItemPercentage(molecule, denominator) {
            let n = '-'
            if (denominator) {
                n = getPercentage(molecule / denominator) + '%'
            }
            return n
        },
        updateItemData(data) {
            const item = { ...data }
            const { cost, fee } = data
            item.costScale = this.getItemPercentage(cost, fee)
            item.feeRoi = cost ? numFormat(fee / cost, 2) : '-'
            return item
        },
        getProviderCategoryListApi() {
            getProviderCategoryList({}).then((res) => {
                const _categoryMap = {}
                res.data.forEach((item) => {
                    _categoryMap[item.id] = item
                    _categoryMap[item.categoryNo] = item
                })
                this.providerCategoryMap = _categoryMap
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-table {
    margin-top: 12px;
    width: 100%;
    .stress {
        color: #f00;
    }
    .el-table__indent {
        padding-left: 8px !important;
    }
}
</style>
