import { hunter_post } from '../http'

export const getOverviewOperating = (data) => {
    return hunter_post({ path: '/overview/operating', data })
}

export const getAdvertTel = (data) => {
    return hunter_post({ path: '/overview/advertisement/tel', data })
}

export const getAdvertKol = (data) => {
    return hunter_post({ path: '/overview/advertisement/kol', data })
}
export const getAdvertPrivate = (data) => {
    return hunter_post({ path: '/overview/advertisement/private', data })
}

export const getOverviewTelemarketing = (data) => {
    return hunter_post({ path: '/overview/telemarketing', data })
}

export const getTelemarketingOppo = (data) => {
    return hunter_post({ path: '/overview/telemarketing/oppoIntent', data })
}

export const getTradeRank = (data) => {
    return hunter_post({ path: '/overview/tradeRank', data })
}

export const getOverviewTradeRank = (data) => {
    return hunter_post({ path: '/overview/tradeRank', data })
}

export const getOverviewTrade = (data) => {
    return hunter_post({ path: '/overview/trade?version=1', data })
}

export const getSpecialSaleData = (data) => {
    return hunter_post({ path: '/overview/special/sale', data })
}
